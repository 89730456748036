import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import { Award, Construction } from "../components/common/Icons";

const ProjectsPage = ({ data }) => {
  if (!data) return null;
  const { page, projects } = data;

  const checkIfEmptyText = (award) => {
    return award.award.text !== "";
  };

  return (
    <>
      <Seo page={page} />
      <Layout>
        <Container>
          <h1 className="py-5">{page.data.title}</h1>
          <Row className="project-collection mb-5">
            {projects.nodes.length ? (
              projects.nodes.map((project) => {
                return (
                  <Col
                    className="project-card mb-4 mb-md-5 px-xl-4"
                    key={project.id}
                    md={6}
                    xxl={4}
                  >
                    <Anchor href={project.url}>
                      <div className="project-image-wraper">
                        <GatsbyImage
                          image={project.data.primary_image.gatsbyImageData}
                          alt={
                            project.data.primary_image.alt ||
                            "Project Primary Image"
                          }
                          className="primary-image"
                        />
                        <GatsbyImage
                          image={project.data.secondary_image.gatsbyImageData}
                          alt={
                            project.data.secondary_image.alt ||
                            "Project Secondary Image"
                          }
                          className="secondary-image"
                        />
                      </div>
                      <div className="project-details d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column align-items-start">
                          <p className="mb-0 link-effect">
                            <strong>{project.data.title}</strong>
                          </p>
                          <p className="mb-0 link-effect">
                            {project.data.location} {project.data.year}
                          </p>
                        </div>
                        {project.data.in_construction ||
                        project.data.awards_list.length ? (
                          <div className="project-badges">
                            {project.data.in_construction && <Construction />}
                            {project.data.awards_list.length &&
                              project.data.awards_list.some(
                                checkIfEmptyText
                              ) && <Award />}
                          </div>
                        ) : null}
                      </div>
                    </Anchor>
                  </Col>
                );
              })
            ) : (
              <h2>There are no projects at this category yet.</h2>
            )}
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query allProjectsQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicProjects(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
      }
    }
    projects: allPrismicProject(sort: { fields: data___year, order: DESC }) {
      nodes {
        id
        url
        data {
          title
          location
          year
          primary_image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          secondary_image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          in_construction
          awards_list {
            award {
              richText
              text
            }
          }
        }
      }
    }
  }
`;

export default ProjectsPage;
